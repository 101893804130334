import { Component, OnInit } from '@angular/core';
import { AuthService, ILogin } from '../auth.service';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  loading: boolean = false;
  formData: any = {};
  login: ILogin | undefined;
  private apiUrl: string = environment.apiUrl;
  twoFactorRequired: boolean = false;
  sessionId: string | null = null; // This will be set to the session ID if 2FA is required
  cookieService: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookie: CookieService
  ) { }

  ngOnInit(): void {
    console.log(`referrer: `+window.document.referrer);
    this.fetchEnvironment();
  }

  onSubmit(e: Event): void {
      e.preventDefault();  // Always prevent the default form submission
      if (this.twoFactorRequired) {
          this.verifyTwoFactorCode(this.formData.twoFactorCode, this.formData.password, this.formData.email);
      } else {
          this.processLogin();
      }
  }


  processLogin(): void {
    const { email, password } = this.formData;
    this.loading = true;
    this.authService.logIn(email, password).subscribe({
      next: (result) => {
        if (result.two_factor_required) {
          this.sessionId = result.session_id;  // Store session ID
          this.twoFactorRequired = true;  // Show 2FA input field
          this.loading = false;
          // Prompt for two-factor authentication code
          notify("Two-factor authentication required.", 'info', 2000);
        } else {
          this.finalizeLogin(result);
        }
      },
      error: (error) => {
        this.loading = false;
        notify(error.error.message || 'Login failed.', 'error', 2000);
      }
    });
  }

  verifyTwoFactorCode(twoFactorCode: string, password: string, email: string): void {
    console.log('Verifying 2FA code...');
    this.loading = true;
    if (!this.sessionId) {
      notify('Session not initialized.', 'error', 2000);
      return;
    }
    this.authService.verify2FA(this.sessionId, twoFactorCode, password, email).subscribe({
      next: (response) => 
        {
          this.twoFactorRequired = false;
          this.finalizeLogin(response);
        },
      error: (error) =>
      {
        this.loading = false;
        notify(error.error.message || '2FA verification failed.', 'error', 2000);
      }
    });
  }

  finalizeLogin(response: any): void {
    this.login = response;
    console.log('Result: ' + JSON.stringify(this.login));
    if(this.login !== undefined && this.login.success === true) {
      console.log('success: '+this.login.success);
      this.cookie.setCookie({name: 'otd-userName', value: this.login.data.name, session: true});
      this.cookie.setCookie({name: 'otd-user', value: JSON.stringify(this.login.data), session: true});
      console.log(JSON.stringify(this.cookie.getCookie('otd-token')));
      this.router.navigate(['/']);
      console.log(`referrer: `+window.document.referrer);
      window.location.href=window.document.referrer;
    }
  }

  clearForm(): void {
    this.formData = {};
    this.loading = false;
  }

  clearCookies() {
    this.cookie.deleteCookie('otd-token');
    this.cookie.deleteCookie('otd-userName');
    this.cookie.deleteCookie('otd-user');
    return;
  }

  fetchEnvironment() {
    let environment: string;
    if (this.apiUrl.includes('test-auth')) {
      environment = 'test';
    } else if (this.apiUrl.includes('develop-auth')) {
      environment = 'develop';
    } else {
      environment = 'production';
    }
    console.log('Environment: ' + environment);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ILogin {
  session_id: string | null;
  two_factor_required: any;
  success: boolean;
  data: {
    token: string;
    name: string;
  };
  message: string;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  withCredentials: true // This enables sending cookies with the request
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public _login: ILogin | undefined;
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  logIn(email: string, password: string): Observable<ILogin> {
    return this.http.post<ILogin>(`${this.apiUrl}/login`, { 'email': email, 'password':password }, httpOptions);
  }

  verify2FA(sessionId: string, twoFactorCode: string, password: string, email: string): Observable<any> {
    const verifyData = { session_id: sessionId, two_factor_code: twoFactorCode, password: password, email: email };
    return this.http.post<any>(`${this.apiUrl}/2fa/verify`, verifyData, httpOptions);
  }

}
